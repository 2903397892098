import React from 'react';

const Work = () => {
  return (
    <div className="work-3__area work-3__inner-wrapper work-3__plr work-3__space">
      <div className="container-fluid">
        <div className="row row-cols-xl-3 row-cols-md-2 justify-content-center">
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-1 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                  <div className="work-3__icon icon-color-1">
                    <span><i className="fas fa-lightbulb-on"></i></span>
                  </div>
                  <div className="work-3__title">
                    <h4 className="work-3__section-title"><a href="#">Software Development</a></h4>
                  </div>
                </div>
                <div className="work-3__right-text">
                  <p>Our custom software development service is dedicated to crafting personalized solutions that perfectly align with your unique business requirements.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-2 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-cogs"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#"> Mobile App Development </a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>With our mobile app development service, we transform your ideas into functional and user-friendly mobile applications.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-3 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-chart-bar"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">Web App Development</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>Our web application development service empowers your business with dynamic and scalable web-based solutions. </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-4 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-lightbulb-on"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">UIUX Designing</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>UI/UX design combines aesthetics, functionality, and user-centered thinking to create seamless and delightful digital experiences.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-6 col-12 wow animate__fadeInUp" data-wow-duration="1.1s">
            <div className="work-3__wrapper inner-wrapper-5 mb-30">
              <div className="work-3__item">
                <div className="work-3__content d-flex align-items-center mb-15">
                <div className="work-3__icon icon-color-1">
                  <span><i className="fas fa-lightbulb-on"></i></span>
                </div>
                <div className="work-3__title">
                  <h4 className="work-3__section-title"><a href="#">Ecommerce Development</a></h4>
                </div>
                </div>
                <div className="work-3__right-text">
                <p>Ecommerce development empowers businesses to establish a robust online presence and drive revenue through seamless online shopping experiences</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Work;