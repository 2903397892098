import React from 'react';
import { Link } from 'react-router-dom';

import CTA from '../../components/CTA';
import SocialFeed from '../../components/SocialFeed';
import ContactTwo from '../../components/Contact/ContactTwo';
import Breadcrumb from '../../components/Breadcrumb';

import aboutImg from '../../assets/img/about/about-1.jpg'
import aboutImg2 from '../../assets/img/about/about-2.jpg'

import bgImg from '../../assets/img/cta/cta-bg-2.png';

const HomeMain = () => {
	return (
		<main>
      <Breadcrumb
				pageTitle= "About Me"
			/>
			  
			<div className="about-5__area about-5__plr">
				<div className="container-fluid">
					<div className="about-5__border">
						<div className="row align-items-center">
							<div className="col-xl-4 col-lg-5 wow tpfadeUp" data-wow-duration="1.1s">
								<div className="about-5__main-img text-center text-lg-start">
									<Link to="/about-us"><img src={aboutImg} alt="" /></Link>
								</div>
							</div>
							<div className='col-xl-1'></div>
							<div className="col-xl-7 col-lg-7">
								<div className="about-5__content-box">
									<h4 className="about-5__content-title wow tpfadeUp" data-wow-duration="1.1s">About Me:</h4>
									<div className="about-5__content">
									<div className="about-5__text wow tpfadeUp" data-wow-duration="1.1s">
										<p>I’m Zaeem Naveed. As a computer scientist with over 6 years of experience, I am passionate about the design and development process. A proven track record of completing over 600 projects to date, I have provided invaluable guidance to numerous startups, enabling them to achieve their goals. I bring a wealth of technical expertise, as well as strong problem-solving and communication skills to every project I undertake.</p>
									</div>
									<div className="about-5__content-list wow tpfadeUp" data-wow-duration="1.1s">
										<ul>
											<li><i className="fal fa-check-circle"></i><span>First Name: Zaeem</span></li>
											<li><i className="fal fa-check-circle"></i><span>Address: Islamabad</span></li>
											<li><i className="fal fa-check-circle"></i><span>Age: 24 Years</span></li>
											<li><i className="fal fa-check-circle"></i><span>Nationality: Pakistani</span></li>
											<li><i className="fal fa-check-circle"></i><span> Freelance: Available</span></li>
											<li><i className="fal fa-check-circle"></i><span> zaeemnaveed5@gmail.com</span></li>
										</ul>
									</div>
									<div className="about-5__button wow tpfadeUp" data-wow-duration="1.1s">
										<Link className="main-btn tp-btn-hover alt-color" to="/about-us">
											<span> More Details</span>
											<b></b>
										</Link>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="social__area social__plr-2 p-relative z-index">
				<div className="container-fluid">
					<SocialFeed />
				</div>
			</div>
			  
      <div className="about-details__area about-details__plr">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 wow tpfadeLeft" data-wow-duration="1.1s">
              	<div className="about-details__main-img">
               	<Link to="/about-us"><img src={aboutImg2} alt="" /></Link>
              	</div>
            </div>
            <div className="col-xl-6 col-lg-6 wow tpfadeRight" data-wow-duration="1.1s">
              	<div className="about-details__content">
               	<h4 className="about-details__title">Personal Awards:</h4>
               	{/* <div className="about-details__text">
                 	<p>Business tailored it design, management & support services business agency elit, sed do eiusmod tempor. </p>
               	</div> */}
               	<div className="about-details__content-list">
									<ul>
										<li><i className="fal fa-check-square"></i><span>Launched a highly successful mobile app with 500,000+ downloads in the first month.</span></li>
										<li><i className="fal fa-check-square"></i><span>Developed a scalable web application that handled heavy user traffic flawlessly. </span></li>
										<li><i className="fal fa-check-square"></i><span>Led a team to deliver a complex software project ahead of schedule, exceeding client expectations.</span></li>
										<li><i className="fal fa-check-square"></i><span>Mentored and trained junior developers, fostering a culture of continuous learning.</span></li>
										<li><i className="fal fa-check-square"></i><span>Received invitations to speak at industry conferences, sharing insights and best practices.</span></li>
										<li><i className="fal fa-check-square"></i><span>Established a reputation for excellence in software development through successful projects.</span></li>
										<li><i className="fal fa-check-square"></i><span>Received positive client feedback, known for translating complex requirements into elegant solutions.</span></li>

									</ul>
               	</div>
               	{/* <div className="about-details__text">
                 	<p>Business tailored it design, management & support services business agency elit, sed do eiusmod tempor. </p>
               	</div> */}
              	</div>
            </div>
            <div className="col-xl-12">
              <div className="about-details__text-2 pt-50 wow tpfadeUp" data-wow-duration="1.1s">
               	<p>
Our achievements highlight our expertise and success in the field of software development. We have a proven track record of launching highly successful mobile apps with a large user base. Our solutions focus on optimizing processes, reducing processing time, and enhancing operational efficiency for our clients. We excel in developing scalable web applications capable of handling heavy user traffic seamlessly. Our contributions to open-source projects and commitment to security have earned us industry recognition. We are also sought-after speakers at conferences, sharing our insights and expertise with fellow professionals. Overall, our achievements demonstrate our dedication to delivering exceptional software solutions.</p>
               	{/* <p>Sed ut perspiciatis unde omnis iste natus error sit voluptate accusantium doloremque laudantium, totam rem aperiam eaquepsa quae abillo inventore veritatis et quasi <br/>architecto beatae vitae dicta sunt explicabo. Nemo enimpsam luptate quia voluptas aspernatur aut fugitdolor sit amet </p>  
               	<p>There are many variations of passages of Lorem Ipsum Fasts There are many variations of passages of Lorem Ipsum Fastsby we are the fast to mane agency fast taking <br/>injected humour,by injected humour, or randomised coved ceilings. are bound to ensue; and equal blame belongs can to bo erwtg mont be business perfect <br/>fao go not be hie best to those who through weakness .....</p>   */}
              </div>
            </div>
          </div>
				</div>
			</div>
			  
			<ContactTwo />
			
			<CTA
				ctaBG={bgImg}
			/>
    </main>
	);
}

export default HomeMain;