import React from 'react';
import SingleTeam from '../../components/Team';

import teamImg1 from '../../assets/img/team/about-1.jpg';
import teamImg2 from '../../assets/img/team/image_3.png';
import teamImg3 from '../../assets/img/team/image_2.png';
import teamImg4 from '../../assets/img/team/1.png';
import teamImg5 from '../../assets/img/team/1.png';
import teamImg6 from '../../assets/img/team/1.png';
import teamImg7 from '../../assets/img/team/1.png';
import teamImg8 from '../../assets/img/team/1.png';

const Team = () => {
  return (
    <div className="team__area team__space-2 team__plr grey-bg">
      <div className="container-fluid">
        <div className="row align-items-end justify-content-center mb-60">
          <div className="col-xl-12 col-lg-6 col-md-9 col-sm-10">
            <div className="feature__section-box text-center">
              <h4 className="section-blue-subtitle title-anim wow animate__fadeInUp" data-wow-duration="1.1s">Meet Our Team Members</h4>
              {/* <h3 className="section-title title-anim wow animate__fadeInUp" data-wow-duration="1.1s">Industries we Meet This <br/>Team Meamber.</h3> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s" >
            <SingleTeam
              Image={teamImg1}
              Title="M. Zaeem Naveed"
              Designation="Founder / CEO"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <SingleTeam
              Image={teamImg2}
              Title="M. Khubaib Bhatti"
              Designation="Designing Team Head / CTO"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <SingleTeam
              Image={teamImg3}
              Title="Tayyab Zaheer"
              Designation="Mobile App Team Head / CTO"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <SingleTeam
              Image={teamImg4}
              Title="Muhammad Ali"
              Designation="Web App Expert"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <SingleTeam
              Image={teamImg5}
              Title="Muhammad  Wali"
              Designation="Junior Designer"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <SingleTeam
              Image={teamImg6}
              Title="Faizan Ahmed"
              Designation="Junior Web Developer"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <SingleTeam
              Image={teamImg7}
              Title="Noor Muhammad"
              Designation="Junior Web Developer"
            />
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 wow animate__fadeInUp" data-wow-duration="1.1s">
            <SingleTeam
              Image={teamImg8}
              Title="Saad Ullah Shah"
              Designation="Junior Web Developer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;