import React from 'react';
import { Link } from 'react-router-dom';

import shapeImg1 from '../../assets/img/work/work-shape-4.png';
import shapeImg2 from '../../assets/img/work/work-shape-5.png';
import shapeImg3 from '../../assets/img/work/work-shape-6.png';
import shapeImg4 from '../../assets/img/work/work-shape-7.png';

const Solution = () => {

    return (
        <div className="solution-area solution__plr solution__space p-relative">
            <div className="solution__shape-1 d-none d-lg-block">
                <img src={shapeImg1} alt="" />
            </div>
            <div className="solution__shape-2 d-none d-lg-block">
                <img src={shapeImg2} alt="" />
            </div>
            <div className="solution__shape-3 d-none d-lg-block">
                <img src={shapeImg3} alt="" />
            </div>
            <div className="solution__shape-4 d-none d-xxl-block">
                <img src={shapeImg4} alt="" />
            </div>
            <div className="container-fluid">
                <div className="solution__wrapper">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="skill__section-box text-center mb-50">
                                <h5 className="section-subtitle title-anim wow animate__fadeInUp" data-wow-duration="1.1s">What We Provide</h5>
                                <h4 className="section-title title-anim wow animate__fadeInUp" data-wow-duration="1.1s">Our Packages</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="solution-item">
                                <div className="solution-top text-center">
                                    <span>Silver 800$</span>
                                </div> 
                                <div className="solution-feature-list">
                                    <ul>
                                        <li><span>5 Pages Mobile App</span> </li>
                                        <li><span>Both Android and IOS.</span> </li>
                                        <li><span>Hybrid App Development</span> </li>
                                        <li><span>Pay Code Violation Fines</span> </li>
                                        <li><span>API Integration</span> </li>
                                        <li><span>Testing and Uploading on App Store</span> </li>
                                    </ul>
                                </div> 
                                <div className="solution-link color-1">
                                    <Link to="/about-us">+92 3470053927 WhatsApp</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="solution-item">
                                <div className="solution-top color-2 text-center">
                                    <span>Gold 1400$</span>
                                </div> 
                                <div className="solution-feature-list">
                                    <ul>
                                    <li><span>10 Pages Mobile App</span> </li>
                                        <li><span>Both Android and IOS.</span> </li>
                                        <li><span>Hybrid App Development</span> </li>
                                        <li><span>Pay Code Violation Fines</span> </li>
                                        <li><span>API Integration</span> </li>
                                        <li><span>Testing and Uploading on App Store</span> </li>
                                    </ul>
                                </div> 
                                <div className="solution-link color-2">
                                <Link to="/about-us">+92 3470053927 WhatsApp</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-6 mb-40 wow animate__fadeInUp" data-wow-duration="1.1s">
                            <div className="solution-item">
                                <div className="solution-top color-3 text-center">
                                    <span>Platinium 2200$</span>
                                </div> 
                                <div className="solution-feature-list">
                                    <ul>
                                        <li><span>20 Pages Mobile App</span> </li>
                                        <li><span>Both Android and IOS.</span> </li>
                                        <li><span>Hybrid App Development</span> </li>
                                        <li><span>Pay Code Violation Fines</span> </li>
                                        <li><span>API Integration</span> </li>
                                        <li><span>Testing and Uploading on App Store</span> </li>
                                    </ul>
                                </div> 
                                <div className="solution-link color-3">
                                <Link to="/about-us">+92 3470053927 WhatsApp</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Solution;